import './style.scss';
import React, { useRef } from 'react';
import { Route, Routes, useNavigate, useSearchParams } from 'react-router-dom';

import { version, Test, Apix } from '@essenza/core';
import { AppRoot, useBreakPoint } from '@essenza/react';

import { AppSchema } from './schema';
import { MainLayout } from './layout/MainLayout';
import { ProfileVista } from './vista/profile/ProfileVista';
import { MobileLayout } from './layout/MobileLayout';
import { LoginVista } from './vista/profile/LoginVista';
import { SigninVista } from './vista/profile/SigninVista';
import { RecoverVista } from './vista/profile/RecoverVista';
import { Welcome } from './vista/Welcome';
import { CheckEmail } from './vista/profile/Signin';
import { SettingVista } from './vista/profile/SettingVista';
import { UserFormVista } from './vista/profile/UserFormVista';
import { HomeVista } from './vista/home';
import { Common, PamService } from '@pam/common';
import { DetailVista } from './vista/detail';
import { TicketRequestVista } from './vista/request';
import { StartVista } from './vista/profile/StartVista';

let initialized = false;

/**
 * 
 * @param {VistaApp} app 
 */
function initApp(app) {

  console.log("INIT APP", app);

  if (!initialized) {
    /*return fetch(window.location.origin + "/app/appsettings.json", { headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' } }).then(r => r.json()).then(r => {
      console.log("CONFIG-DATA", r);
      initialized = true;
    });*/
    initialized = true;
    Common.initialize(app);
    app.settings.usertype = 4; //se gestisce più ruoli mettere array
    app.settings.route = [""];
    app.settings.router = ["/admin", "/admin", "/partner", "/partner", "/", "/member"];
    Apix.apiUrl = "api/costumer/";

  }

  return null;
}

function onload(app) {
  const qp = app.qp;
  if (qp && qp.has("orderid") && qp.has("email")) {
    app.irequest = { type: 'ORD' };
    app.control.request(PamService, r => r.checkWC(qp.get("orderid"), qp.get("email"))).then((r) => {
      console.log("CHECK-WC RESULT", r);
      app.settings.wc = r;
      r.orderid = qp.get("orderid");
      r.email = qp.get("email");
      //r.user === 'R'? app.control.navigate("login") : app.control.navigate("start", r);
    });
  }
}
/**
 * 
 * @param {VistaApp} app 
 */
function onlogin(app) {
  console.log("ON LOGIN", app);
  app.navigate("/home");
}

function AppController(c) {
  c.skin = App;
  c.command = null;
}

// https://localhost:44340/  https://app.praticheamiche.it/

function App() {
  console.log("APP-PROVIDER-BP", version, Test);
  const nav = useNavigate();
  const [qp] = useSearchParams();

  const token = useRef(); //AppProxy
  const breakpoint = useBreakPoint('md');

  return (
    <AppRoot token={token} onload={onload} init={initApp} control={AppController} navigator={nav} qp={qp} onlogin={onlogin} baseurl="https://app.praticheamiche.it/" schema={AppSchema}>
      <Routes>
        <Route path="/" element={<MainLayout token={token} />}>
          <Route path="home" element={<HomeVista />} />
          <Route path="settings" element={<SettingVista />} />
          <Route path="detail" element={<DetailVista />} />
          <Route path="request" element={<TicketRequestVista />} />
          <Route path="userform" element={<UserFormVista />} />
        </Route>
        {/*         {breakpoint.md.active
          ? <Route path="/" element={<MainLayout token={token} />}> 
          <Route path="home" element={<HomeVista />} />
          <Route path="settings" element={<SettingVista />} />
          <Route path="detail" element={<DetailVista />} />
          <Route path="userform" element={<UserFormVista />} />
        </Route>
          :
          <Route path="/" element={<MobileLayout token={token} />}>
          </Route>
        } */}
        <Route index element={<Welcome token={token} />} />
        <Route path="login" element={<LoginVista />} />
        <Route path="start" element={<StartVista />} />
        <Route path="profile" element={<ProfileVista />} />
        <Route path="signin" element={<SigninVista />} />
        <Route path="onsignin" element={<CheckEmail />} />
        <Route path="loginrec" element={<RecoverVista />} />
      </Routes>
    </AppRoot>
  );
}

export default App;
