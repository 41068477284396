import { Button, Col, Radio, Row, Tooltip } from "antd";
import { ExclamationOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { useControl, FormixItem, VistaContext, Uploader } from "@essenza/react";
import { useContext, useEffect, useState } from "react";
//import { Uploader } from "./uploader";

function Controller(c) {
    c.skin = DocumentUplod;
    c.command = {

        PREVIEW: async (url, { control }) => {
            window.open(url, '_blank').focus();
        },

        REMOVE: (file, { node, data }) => {
            node.delete(file.id); //data.files
            //c.model.setFileList(node.data);
        }
    }
}

export function DocumentUplod({ document, source, idparent, idlinked, extra, optionalExtra, url, parentField="idprocedure", linkedField="idperson" }) {
    const [control] = useControl(Controller);
    const context = useContext(VistaContext);
    const item = document || source.data;
    const optional = item?.optional;

    const [enabled, setEnabled] = useState(!optional || item.files.length > 0);

    /*if(optional && optional.field)
        item.*/

    useEffect(() => {
        //item.required = !optional || item.files.length > 0;
        setEnabled(!optional || item.files.length > 0);//item.required);
    }, [optional, item])

    const onEnabling = (e) => {
        item.required = e.target.value === 0;
        setEnabled(item.required);
    }

    const onsuccess = (r, file) => {
        file.id = r.data.id;
        file.itype = item.itype;
        file.url = r.data.url;
        item.files.push(file);//utilizzare node???
        item.invalid = false;
        source.add(file, false, true);
    }

    const onremove = (file) => {
        console.log("DOCUMENT REMOVED", file.name, file.id);
        control.execute("REMOVE", file, source);
        //TODO: source.remove(file);
    }

    const info = { itype: item.itype, igroup: item.igroup || 0, idparent: idparent };
    info[parentField] = idparent;
    if(item.igroup) info.igroup = item.igroup;
    if(idlinked) info[linkedField] = idlinked;

    return (<div className="w100 radius-md mt-lg bg-block bw-1 bs shadow-md">
        <Row className={(enabled? "bg-pri radius-top-md" : "bg-lite radius-md")+ " padding-sm w100 flex-right"}  align="middle">
            <Col flex="auto" className="h6">
                <Tooltip title={item.tooltip}>
                <InfoCircleOutlined />
                </Tooltip>
                <span className="ml-sm v-middle"><b >{item.title || item.name}</b></span>
            </Col>
            
            {optional && (optional.field
                ? <Col flex="none">
                    <FormixItem style={{marginBottom: '0px'}} name={optional.field}>
                        <Radio.Group onChange={onEnabling}>
                            <Radio value={0}>{optional.yesLabel || "Si"}</Radio>
                            <Radio value={1}>{optional.noLabel || "No"}</Radio>
                        </Radio.Group>
                    </FormixItem>
                </Col>
                : <Col flex="none" >
                    <Radio.Group  defaultValue={enabled ? 0 : 1} onChange={onEnabling}>
                        <Radio value={0}>{"Si"}</Radio>
                        <Radio value={1}>{"No"}</Radio>
                    </Radio.Group>
                </Col>)
            }
        </Row>

        {enabled && <>
            {item.invalid && <div className="bg-red py-sm"><ExclamationOutlined style={{fontSize: '18px'}} /><span style={{fontSize: '18px'}}><b>DOCUMENTO RICHIESTO</b></span></div>}
            <div className="px-md pt-sm w100"><h6>{item.tooltip}</h6></div>
            <div className="w100 padding-md">
                <Uploader disabled={context?.disabled || item.readonly} className="w100" url={url || "api/costumer/dupload"} data={info} restrictTo="png,jpg,pdf"
                    onRemove={onremove} onSuccess={onsuccess} outsource={true} fileList={item.files} multiple={true} name="formFile">
                    { !item.readonly && !context?.disabled && <Button className="btn-lite mb-md centered">{item.label || "Carica uno o più file"}</Button> }
                </Uploader>
                {optionalExtra}
            </div>
            
        </>}
        {extra}
    </div>);
}