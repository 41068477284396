import React from 'react';
import { Col, Button, Input } from "antd";
import * as yup from 'yup';

import { Formix, FormixItem, useModel, useForm } from '@essenza/react';

const { TextArea } = Input;

function Controller(c) {
    c.skin = MessageForm;
    c.command = {
        SEND: async (form, { source, node, data }) => {

            const result = await form.validate();

            if (result.isValid) {
                source.mutate("date", new Date());
                const root = node.parent;
                if (root) {
                    return root.save();
                }
                else return node.save();
            }
            else {
                return c.StopFlow();
            }
        },
    }
}

export function MessageForm({ source, mtype }) {
    const [model] = useModel(MessageForm, Controller);
    model.mtype = mtype;
    const form = useForm("message-form", source, model, null, yup.object({
        body: yup.string().required("Messaggio vuoto!"),
    }));

    console.log("MESSAGE-FORM", source);

    return (
        <Formix form={form} layout='vertical' className="ec-form bg-block radius-md padding-xl shadow-md">
            <FormixItem label="Messaggio" name="body">
                <TextArea rows={6} placeholder="Testo messaggio..."></TextArea>
            </FormixItem>
            <Col span={24} className="text-center">
                <Button className="btn-lite" onClick={() => model.emit("CANCEL")}>ANNULLA</Button>
                <Button className="btn-dark ml-sm" onClick={() => model.emit("SEND", form, null, source)}>INVIA</Button>
            </Col>
        </Formix>
    )
}
