import { Avatar, Col, List, Row, Button, Collapse } from "antd";

import React, { useMemo } from 'react';

import { MessageForm } from "../view/ticket/message";
import { GraphSchema, BreakFlow } from "@essenza/core";
import { Vista } from "@essenza/react";
import { useModel, useVista } from "@essenza/react";

import { useLocation } from "react-router-dom";


function Controller(c) {
    c.skin = TicketRequestVista;

    c.command = {

    }

    //Non funzione perchè è un'altra vista => altro context!
    c.observe(MessageForm, {
        SEND: async (_, {source, node}) => {
            const root = node.parent;

            if(root){
                const d = new Date();
                root.mutate("date", d, source.parent);
                source.mutate("date", d);
                root.save().then((r)=>{
                    source.merge();
                    c.navigate(-1);//"detail",{state:{id: source.data.idprocedure, tab: 'T'}});
                    //OSS: se è un nuovo message o ticket allora dovrei anche settare gli id....
                });
            }
        },

        CANCEL: () =>{
            c.navigate(-1);
        }
    });
}

export function TicketRequestVista() {
    const [ctx, model, control] = useVista(TicketRequestVista, Controller);

    const data = useLocation().state; //{ idticket: ticket.id, idowner: app.session.id, idprocedure: procedure.id }
    
    const source = useMemo(() => {
        return model.source("ticket", null, data)
    }, [data, model]); 
    //Variante qui creo ticket e Message form si comporta normalmente, però se poi annullo devo cancellare ticket creato
    console.log("TICKET-REQUEST-VISTA");

    return (
        <Vista context={ctx} >
            <div className="w100">
                <div className="centered max-width-md padding-sm">
                <h3 className="my-lg text-center">Nuova Richiesta Supporto</h3>
                <MessageForm source={source.get("messages")} />
                </div>
            </div>
        </Vista>
    )
}