import { Formix, useModel, useForm, useControl } from "@essenza/react";
import { Col, Button, message } from 'antd';
import React, { useMemo, useRef, useState } from "react";
import * as yup from 'yup';
import dayjs from 'dayjs';
import { LeasePart, UDocument, DeclarationSchema, DeclarationPart, LeaseSchema, schemaLEASE } from '@pam/common';
//import { ExclamationOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { PersonList } from "../personList";
import { DocumentUplod } from "../../../component/DocumentUpload";
import { DataSource } from "@essenza/core";
import { ProcedurePerson } from "../person";

function Controller(c) {
    c.skin = LeasView;
    c.command = {
        SAVE: async (msg, { control }) => {
            //console.log("SAVE", source);
            let form = control.form("profile-form");
            let result = await form.validate();
            console.log("COURSE FORM VALIDATION", form, result);
            if (result.isValid) {
                if (!result.data.complete)
                    result.node.mutate("complete", true, result.data);

                result.node.save().then((r) => {
                    c.navigate("/offerte");
                    if (msg)
                        msg.success('Profilo aggiornato con successo!', 2); //()=>control.navigate("/")
                });
            }
        },

        SEND: async (docs, { model, info }) => {

            let docValid = true;

            const validateDoc = doc => {
                if (doc.required && (doc.files.length === 0)) {
                    doc.invalid = true;
                    docValid = false;
                }
            }

            docs.forEach(doc => validateDoc(doc));

            let result = await c.validate(ProcedurePerson, "form");
            //debugger
            //TODO: result.forEach/ model.forEachState/forEachProp
            if (result.model) {
                if(Array.isArray(result.model)){
                    result.model.forEach(model => {
                        model.state.attachs.forEach(doc => validateDoc(doc));
                    });
                }
                else{
                    result.model.state.attachs.forEach(doc => validateDoc(doc));
                }
                
            }

            let lresult = await c.validate(LeasView, "form");

            console.log("LOC FORM VALIDATION", result, lresult, docValid);

            if (result.isValid && lresult && docValid) {
                const ds = model.procedure;
                if (info.istate === 2)
                    ds.node.mutate("istate", 3, ds.data);
                else if (info.istate === 5)
                    ds.node.mutate("istate", 4, ds.data);
                
                ds.node.mutate("filled", true, ds.data);

                ds.node.save().then(() => {
                    if (info.istate === 1) message.warning("I dati sono stati salvati con successo, la pratica sarà elaborata al ricevimento contabile del pagamento.", 16);
                    else if (info.istate === 2) message.warning("La pratica è stata inviata con successo.", 10);
                    else message.info("I dati sono stati aggiornati con successo. Segui la pratica per eventuali ulteriori richieste di informazioni.", 16);
                    c.navigate("home");
                });
            }
            else {
                message.warning("DATI MANCANTI O ERRATI, CONTROLLA IL FORM.", 8);
                if (!docValid)
                    model.refresh();
            }
        },
    }
}

export function LeasView({ source, info }) {
    //const source = useGraph2(LessonModel, "item");
    //const [control] = useControl(Controller);
    const [model, control] = useModel(LeasView, Controller);
    const schema = useRef(yup.object(model.initSchema(yup, [DeclarationSchema, LeaseSchema])));
    model.procedure = source;
    const form = useForm("form", source, model,
        { dstart: (v) => v ? dayjs(v) : null, dend: (v) => v ? dayjs(v) : null, odate: (v) => v ? dayjs(v) : null, }, schema.current);

    const [attachs, docsRoot] = useMemo(() => {

        const schema = [
            {
                itype: 0, name: "LOCATORE", title: "Carica la carta d'identità, scansione o foto del documento fronte e retro anche in più file.", files: [], required: true,
                group: [
                    { itype: 0, name: "CARTA IDENTITA'", title: "Carica la carta d'identità, scansione o foto del documento fronte e retro anche in più file.", files: [], required: true, },
                    { itype: 1, name: "TESSERA SANITARIA", title: "Foto della tessera sanitaria, fronte e retro.", files: [], required: true },
                ]
            },
            {
                itype: 1, name: "CONDUTTORE", title: "Carica la carta d'identità, scansione o foto del documento fronte e retro anche in più file.", files: [],
                group: [
                    { itype: 0, name: "CARTA IDENTITA'", title: "Carica la carta d'identità, scansione o foto del documento fronte e retro anche in più file.", files: [], required: true, },
                    { itype: 1, name: "TESSERA SANITARIA", title: "Foto della tessera sanitaria, fronte e retro.", files: [], required: true, },
                    { itype: 2, name: "PERMESSO DI SOGGIORNO", title: "Permesso di soggiorno, scansione o foto del documento fronte e retro anche in più file.", files: [], required: true, },
                ]
            },

            { itype: 2, name: "VISURA CATASTALE", title: "Dati catastali dell’immobile oggetto di locazione.", files: [], required: true, },
            { itype: 3, name: "APE", title: "Attestazione di prestazione energetica.", files: [], required: true, },
            { itype: 4, name: "DISDETTA", title: "Modalità e termini in caso di disdetta di una delle parti.", files: [], required: true, },
            { itype: 5, name: "CONTRATTO DI LOCAZIONE", title: "Copia del contratto di locazione con ricevuta di registrazione", files: [], required: true, },
        ]

        UDocument.format(schemaLEASE, source.data?.documents);

        return [schemaLEASE, source.discendant("documents")]; //control.source("document", "list")

    }, [source.data]);

    if (!source.data) return null;

    const data = source.data;
    console.log("PASSA-ADD LOCAZIONE");
    return (
        <>
            <PersonList title="Dati Locatore" suffix="Altro Locatore" name="Locatare" group={0} source={source.get("persons").filter((e) => e.itype === 0).defaultValue({itype: 0})} idparent={data.id} />
            <PersonList title="Dati Conduttore" suffix="Altro Conduttore" name="Conduttore" group={1} source={source.get("persons").filter((e) => e.itype === 1).defaultValue({itype: 1})} idparent={data.id} />
            <h3 className='text-center mt-lg'>DATI LOCAZIONE</h3>
            <Formix control={control} form={form} layout='vertical' className="ec-form bg-block mt-lg radius-md padding-xl shadow-md">
                <LeasePart kind={data.kind} />
                <DeclarationPart />
            </Formix>
            <h3 className="text-center mt-lg">Caricamento dei documenti allegati richiesti</h3>
            <h6 className="text-center mt-sm">Formati consentiti pdf, jpg, jpeg, png</h6>
            <div className="bg-block mt-lg radius-md padding-xl shadow-md">
                {attachs.map((item, i) => item.itype > 2 ? <DocumentUplod key={i} optional document={item} source={source.get("documents")} idparent={data.id} /> : null)}
            </div>
            <Button disabled={!info.sendable} onClick={() => model.emit("SEND", attachs, { info: info })} size="large" className="btn-pri centered my-lg" >Invia i dati per procedere con la pratica</Button>
            {info.istate === 1 && <Button onClick={() => model.emit("SEND", attachs, { info: info })} size="large" className="btn-pri centered my-lg" >Salva le Informazioni</Button>}
        </>
    )
} 