import { Col, DatePicker, Input, InputNumber, Radio, Row } from "antd";
import { useState } from "react";
import { FormixItem } from '@essenza/react';
import { ProcedureCompany } from "../person";

export function Dependant({ value }) {
    const [joint, setJoint] = useState(value);
    
    return (<>
        <FormixItem name="idependant" label="Coniuge a carico">
            <Radio.Group className="bg-lite padding-md radius-md bw-1 bc-lite w100" defaultValue={joint} value={joint} onChange={(e) => { setJoint(e.target.value); }}>
                <Radio value={0}>No</Radio>
                <Radio value={1}>Si</Radio>
            </Radio.Group>
        </FormixItem>

        {joint === 1 && <>
            <Row gutter={16}>
                <Col span={24} lg={12}>
                    <FormixItem label="Codice Fiscale Coniuge" name="jointcf">
                        <Input placeholder="CF Coniuge"></Input>
                    </FormixItem>
                </Col>
                <Col span={24} lg={12}>
                    <FormixItem label="Mesi a Carico" name="iload">
                        <InputNumber min={0} max={12} defaultValue={1} />
                    </FormixItem>
                </Col>
            </Row>
        </>}
        <FormixItem label="Figli a Carico" name="jvalues">
            <DependantList />
        </FormixItem>

    </>)
}

function DependantList({value, onChange, onBlur, ...rest}) {

    const [count, setCount] = useState(value?value.length:0);
    const [_value, setValue] = useState(value);

    const onNumChange = (num) => {
        console.log('changed', num);
        let v;
        if(num === 0){
            v = [];
        }
        else{
            if(!v) v = [];
            const len = v.length;
            if(num > len){
                for (let k = 0; k < (num-len); k++) {
                    v.push({load: 1});
                }
            }
            else if(len>num){
                v.splice(num, len-num)
            }
        }

        setValue(v);
        onChange(v);
        setCount(v?v.length:0);
    };
    return (<div onBlur={onBlur} {...rest }>
        <span className="pr-md"><b>Numero Figli a Carico</b></span><InputNumber className="mb-sm" min={0} max={30} defaultValue={count}  onChange={onNumChange} />
        {
            _value && _value.map((item, i)=><Row className="mb-md" key={i} gutter={16} >
            <Col span={24} lg={12}>
                <Input defaultValue={item.cf}  onBlur={(e)=>{item.cf=e.target.value; console.log("VALUES CHANGE 2",_value);onChange(_value);}} placeholder={"Codice Fisacle Figlio " + (i+1)}></Input>
            </Col>
            <Col className="pt-sm" span={24} lg={12}>
            <span className="pr-md"><b>{"Mesi a carico Figlio " + (i+1)}</b></span>
                <InputNumber addonAfter="%" style={{width: '90px'}} placeholder={"Mesi a carico Figlio " + (i+1)} defaultValue={item.load} onBlur={(e)=>{item.load=e.target.value; onChange({..._value});}} min={0} max={100} />
                
            </Col>
        </Row>)
        }
    </div>)
}