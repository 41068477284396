import { Input, Radio} from "antd";
import { useState } from "react";
import { FormixItem } from '@essenza/react';
import { ProcedureCompany } from "../person";

export function AgentPart({value, source}) {
    const [agent, setAgent] = useState(value || 0);
    const year = new Date().getFullYear();

    /*const company = useMemo(()=>{
        let ds = source.filter((person) => person.itype === 1);

        if(!ds.data || ds.data.length === 0){
            ds = source.bind({itype: 1});
        }

        return ds;

    },[source.data]);*/


    return (<>
        <FormixItem name="iagent" label={"Sostituto d’imposta che effettuerà il conguaglio nel " + year}>
            <Radio.Group className="bg-lite padding-md radius-md bw-1 bc-lite w100" defaultValue={agent} value={agent} onChange={(e) => { setAgent(e.target.value); }}>
                <Radio value={0}>Non Presente</Radio>
                <Radio value={1}>Come da CU</Radio>
                <Radio value={2}>Altro</Radio>
            </Radio.Group>
        </FormixItem>
        {agent === 0 &&
            <>
                <FormixItem label="IBAN del conto corrette su cui ricevere accredito o addebito" name="iban">
                    <Input placeholder="IBAN"></Input>
                </FormixItem>
            </>
        }
        {agent === 2 && <>
            <h3 className="mb-lg">Dati Sostituto d'imposta</h3>
            <ProcedureCompany source={source} />
        </>}
        {agent === 1 &&
            <>
                <FormixItem label="In caso di più CU specificare la ragione sociale" name="tbusinessname">
                    <Input placeholder="Ragione Sociale"></Input>
                </FormixItem>
            </>
        }
    </>)
}