import { Button, Collapse, Badge } from "antd";

import React, { useMemo, useState } from 'react';
import { useModel, useApp } from '@essenza/react';
import { PamService } from '@pam/common';

import { EditOutlined, MailOutlined } from '@ant-design/icons';
import { MessageForm } from "./message";
import { BreakFlow } from "@essenza/core";
import { TicketRequest } from "./request";
import { useEffect } from "react";

const { Panel } = Collapse;

function Controller(c) {
    c.skin = TicketView;
    c.command = {
        DETAIL: (source) => {
            c.setSource("users.item", source.data);
            c.navigate("detail", { state: { label: 'Aggiorna', isUpdate: true } });
        },
        NEW_REQUEST: (info, { data, node }) => {
            info.date = new Date();
            info.subject = "Richiesta Supporto ";
            info.messages = { idowner: info.idcostumer, idprocedure: info.idprocedure };
            c.navigate("request", info);
            //node.addItem(info, null, true); //Da Aggiungere unshift
            //debugger;
            //devo aggiornare view es potrei implementare control.refresh()
        },
        READ: (ticket, { node }) => {
            c.request(PamService, r => r.ticketRead(ticket.id)).then((r) => {
                ticket.read = true;
                ticket.udate = new Date();
                //node.refresh();
            })
        }
    }

    c.observe(MessageForm, {
        SEND: {
            before: (_, { source, node, data, model }) => {
                //debugger
                //if (model.mtype === "own") {
                /*const n = node.parent;
                n.mutate("istate", 2, source.parent);
                n.mutate("read", false, source.parent);*/
                //n.mutate("udate", new Date(), source.parent);
                source.mutateParent("istate", 2);
                source.mutateParent("read", false);
                source.mutateParent("udate", new Date());
                //}
            },
            after: async (form, { source, node }) => {
                const model = c.getState(TicketView).firstOrDefault();
                model.state.setRequest(false);
                model.state.setIndex(-1);
                return BreakFlow;
            },
        },

        CANCEL: () => {
            const model = c.getState(TicketView).firstOrDefault();
            model.state.setRequest(false);
            model.state.setIndex(-1);
            return BreakFlow;
        }
    });
}

export function TicketView({ source, procedure, selected = "0" }) {
    const [model, control, state] = useModel(TicketView, Controller);
    [state.index, state.setIndex] = useState(-1);
    [state.request, state.setRequest] = useState(false);
    const app = useApp();
    console.log("TICKET-LIST", source);

    const owner = parseInt(app.session.id);

    const requestSource = useMemo(() => {
        if (state.request) {
            const ticket = {
                messages: [{ date: new Date(), idowner: owner, idprocedure: procedure.id }], subject: 'Richiesta Supporto', itype: 0,
                date: new Date(), idcostumer: owner, istate: 2, idplatform: procedure.idplatform, read: false, waiting: false,
                //idplatform: procedure.istate !== 1? procedure.idplatform : 0, assegno ticket in stato ATTESA PAGAMENTO ad  ADMIN e non a partner
            };
            if (procedure.idoperator) ticket.idoperator = procedure.idoperator;
            return source.temp(ticket, true)
        }
    }, [state.request, procedure]);

    const onchange = (v) => {
        state.setIndex(-1);
        if (v !== undefined && source.data) {
            const ticket = source.data[v];
            if (!ticket.read && ticket.istate === 1) {
                control.execute("READ", ticket, source);
            }
            console.log(v)
        }
    }

    useEffect(() => {
        onchange(selected);
    }, [selected])

    if (!source.data || !source.data[0]) return (<div className="text-center py-lg">
        {state.request ?
            <TicketRequest source={requestSource} />
            : <>
                <h2>Nessuna Richiesta Presente</h2>
                {procedure.istate > 0 && <Button onClick={() => state.setRequest(true)} className="mt-lg btn-dark">Nuova Richiesta</Button>}
            </>
        }
    </div>)

    const onMessage = (e, i) => {
        e.stopPropagation();
        state.setIndex(i);
    }

    return (
        <div className="w100 text-right">
            {procedure.istate > 0 && <Button onClick={() => state.setRequest(true)} className="mt-lg btn-dark">Nuova Richiesta</Button>}
            {state.request && <TicketRequest source={requestSource} />}
            <Collapse onChange={onchange} defaultActiveKey={["0"]} className="mt-lg text-left" accordion>
                {
                    source.data.map((ticket, i) => {
                        return <Panel className=" fw-7" header={<><span className={(ticket.istate > 0 ? "dot-blue" : "dot-dark") + " mr-sm"}></span><span>{ticket.subject + ' n. ' + ticket.id + ' del ' + new Date(ticket.date).toLocaleDateString()}</span></>} key={i}
                            extra={
                                ticket.istate === 1 && !ticket.read
                                    ? <div>
                                        <Badge count={1} size="small">
                                            <MailOutlined style={{ fontSize: '20px' }} />
                                        </Badge>
                                    </div>
                                    : ticket.istate === 0 && <span className="pl-sm">CHIUSA</span>
                            }>

                            {
                                state.index === i
                                    ? <MessageForm mtype="own" source={source.at(i).get("messages").temp({ idowner: owner, idprocedure: procedure.id })} />
                                    : ticket.istate > 0 && <Button type="link" className="text-right" onClick={(e) => onMessage(e, i)}><EditOutlined />Scrivi Nuovo Messaggio</Button>
                            }

                            {
                                ticket.messages.map((message) => {
                                    if (!message) return null;
                                    return <div className={"mt-md padding-md radius-md ticket" + (message.idowner === owner ? "-own bg-pri " : " bg-main")}>
                                        <div className="font-sm fw-2">{new Date(message.date).toLocaleString("it-IT")} </div>
                                        {message.body}
                                    </div>
                                })
                            }
                        </Panel>
                    })
                }
            </Collapse>
        </div>
    )
}