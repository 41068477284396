import { Checkbox, Radio } from "antd";
import { FormixItem } from '@essenza/react';
import { useRef } from "react";

export function DepositPart() {
    const year = useRef(new Date().getFullYear());
    return (<>
        <FormixItem label="RATEIZZAZIONE IN CASO DI DEBITO" name="worker">
            <Radio.Group className="bg-lite padding-md radius-md bw-1 bc-lite w100">
                <Radio value={0}>Si</Radio>
                <Radio value={1}>No</Radio>
            </Radio.Group>
        </FormixItem>
        <FormixItem label={"ACCONTI " + year.current + " DA VERSARE"} name="retired">
            <Radio.Group className="bg-lite padding-md radius-md bw-1 bc-lite w100">
                <Radio value={0}>Si</Radio>
                <Radio value={1}>No</Radio>
            </Radio.Group>
        </FormixItem>
    </>)
}