import { Input, Radio, Row, Select } from "antd";
import { useState } from "react";
import { FormixItem } from '@essenza/react';

export function XDest({ procedure }) {

    return (<div className=" bg-block my-md radius-md padding-xl shadow-md">
        <Dest data={procedure.ioption} value={procedure.inote} label="8x1000" ifield="ioption" field="inote" options={[
            { value: 'A', label: 'STATO' },
            { value: 'B', label: 'CHIESA CATTOLICA' },
            { value: 'C', label: 'UNIONE CHIESE CRISTIANE AVVENTISTE DEL 7° GIORNO' },
            { value: 'D', label: 'ASSEMBLEE DI DIO IN ITALIA' },
            { value: 'E', label: 'CHIESA EVANGELICA VALDESE (Unione delle Chiese metodiste e Valdesi)' },
            { value: 'F', label: 'CHIESA EVANGELICA LUTERANA IN ITALIA' },
            { value: 'G', label: 'UNIONE COMUNITA’ EBRAICHE ITALIANE' },
            { value: 'H', label: 'SACRA ARCIDIOCESI ORTODOSSA D’ITALIA ED ESARCATO  PER L’EUROPA MERIDIONALE' },
            { value: 'I', label: 'CHIESA APOSTOLICA IN ITALIA' },
            { value: 'J', label: 'UNIONE CRISTIANA EVANGELICA BATTISTA D’ITALIA' },
            { value: 'K', label: 'UNIONE BUDDHISTA ITALIANA' },
            { value: 'L', label: 'UNIONE INDUISTA ITALIANA' },
            { value: 'M', label: 'ISTITUTO BUDDISTA ITALIANO SOKA GAKKAI (IBISG)' },
        ]} />
        <Dest data={procedure.iioption} value={procedure.iinote} label="5x1000" ifield="iioption" field="iinote" options={[
            { value: 'N', label: 'SOSTEGNO DEL VOLONTARIATO E DELLE ALTRE ORGANIZZAZIONI NON LUCRATIVE DI UTILIA’ SOCIALE, DELLE ASSOCIAZIONI DI PROMOZIONE SOCIALE E DELLE ASSOCIAZIONI E FONDAZIONI RICONOSCIUTE CHE OPERANO NEI SETTORI DI CUI ALL’ART. 10, C. 1, LETT A), DEL D.LGS. N. 460 DEL 1977' },
            { value: 'O', label: 'FINANZIAMENTO DELLA RICERCA SCIENTIFICA E DELLE UNIVERSITA’' },
            { value: 'P', label: 'FINANZIAMENTO DELLA RICERCA SANITARIA' },
            { value: 'Q', label: 'FINANZIAMENTO A FAVORE DI ORGANISMI PRIVATI DELLE ATTIVITA’ DI TUTELA, PROMOZIONE E VALORIZZAZIONE DEI BENI CULTURALI E PAESAGGISTICI' },
            { value: 'R', label: 'SOSTEGNO DELLE ATTIVITA’ SOCIALI SVOLTE DAL COMUNE DI RESIDENZA' },
            { value: 'S', label: 'SOSTEGNO ALLE ASSOCIAZIONI SPORTIVE DILETTANTISTICHE RICONOSCIUTE AI FINI SPORTIVI DAL CONI A NORMA DI LEGGE CHE SVOLGONO UNA RILEVANTE ATTIVITA’ DI INTERESSE SOCIALE' },
        ]} />
        <Dest data={procedure.iiioption} label="2x1000" ifield="iiioption" field="iiinote" />
    </div>)
}

export function Dest({ data, value, label, ifield, field, options }) {
    const [dest, setDest] = useState(data);
    const [option, setOption] = useState(value);

    return (<>
        <FormixItem name={ifield} label={"Destinazione del " + label}>
            <Radio.Group className="bg-lite padding-md radius-md bw-1 bc-lite w100" defaultValue={dest} value={dest} onChange={(e) => { setDest(e.target.value); }}>
                <Radio value={0}>SCELTA {label}</Radio>
                {/* <Radio value={1}>VEDI DOCUMENTAZIONE ALLEGATA</Radio> */}
                <Radio value={2}>NESSUNA SCELTA</Radio>
            </Radio.Group>
        </FormixItem>

        {dest === 0 &&
            <>
                {(field === 'inote' || field === 'iinote') && <FormixItem label="" name={field}>
                    <Radio.Group className="bg-lite padding-md radius-md bw-1 bc-lite w100" onChange={(e) => setOption(e.target.value)} options={options} />
                    {/* <Select onSelect={(v)=>setOption(v)} options={options}></Select> */}
                </FormixItem>}

                {
                    option === 'A' && <FormixItem label="Codice Stato" name="declaration">
                        <Select defaultValue={0} style={{width: '180px'}}
                            options={[
                                {value: 0, label: 'Nessuno',},
                                {value: 1, label: 'Fame nel mondo',},
                                {value: 2, label: 'Calamita',},
                                {value: 3, label: 'Edilizia scolastica',},
                                {value: 4, label: 'Assistenza rifugiati',},
                                {value: 5, label: 'Beni culturali',},
                            ]}
                        />
                    </FormixItem>
                }

                {field === 'iinote' && "NOPS".indexOf(option) > -1 && <FormixItem label="Codice fiscale del beneficiario (eventuale) 5x1000" name="deposit">
                    <Input placeholder="CF beneficiario"></Input>
                </FormixItem>}

                {field === 'iiinote' && <FormixItem label="Codice fiscale del beneficiario o Nome del partito beneficiario 2x1000" name="bank">
                    <Input placeholder="CF beneficiario"></Input>
                </FormixItem>}
            </>
        }
    </>)
}

export function XXDest({ procedure }) {

    return (<div className=" bg-block my-md radius-md padding-xl shadow-md">
        <XXXDest data={procedure.xoption} value={procedure.xnote} label="8x1000" ifield="xoption" field="xnote" options={[
            { value: 'A', label: 'STATO' },
            { value: 'B', label: 'CHIESA CATTOLICA' },
            { value: 'C', label: 'UNIONE CHIESE CRISTIANE AVVENTISTE DEL 7° GIORNO' },
            { value: 'D', label: 'ASSEMBLEE DI DIO IN ITALIA' },
            { value: 'E', label: 'CHIESA EVANGELICA VALDESE (Unione delle Chiese metodiste e Valdesi)' },
            { value: 'F', label: 'CHIESA EVANGELICA LUTERANA IN ITALIA' },
            { value: 'G', label: 'UNIONE COMUNITA’ EBRAICHE ITALIANE' },
            { value: 'H', label: 'SACRA ARCIDIOCESI ORTODOSSA D’ITALIA ED ESARCATO  PER L’EUROPA MERIDIONALE' },
            { value: 'I', label: 'CHIESA APOSTOLICA IN ITALIA' },
            { value: 'J', label: 'UNIONE CRISTIANA EVANGELICA BATTISTA D’ITALIA' },
            { value: 'K', label: 'UNIONE BUDDHISTA ITALIANA' },
            { value: 'L', label: 'UNIONE INDUISTA ITALIANA' },
            { value: 'M', label: 'ISTITUTO BUDDISTA ITALIANO SOKA GAKKAI (IBISG)' },
        ]} />
        <XXXDest data={procedure.xxoption} value={procedure.xxnote} label="5x1000" ifield="xxoption" field="xxnote" options={[
            { value: 'N', label: 'SOSTEGNO DEL VOLONTARIATO E DELLE ALTRE ORGANIZZAZIONI NON LUCRATIVE DI UTILIA’ SOCIALE, DELLE ASSOCIAZIONI DI PROMOZIONE SOCIALE E DELLE ASSOCIAZIONI E FONDAZIONI RICONOSCIUTE CHE OPERANO NEI SETTORI DI CUI ALL’ART. 10, C. 1, LETT A), DEL D.LGS. N. 460 DEL 1977' },
            { value: 'O', label: 'FINANZIAMENTO DELLA RICERCA SCIENTIFICA E DELLE UNIVERSITA’' },
            { value: 'P', label: 'FINANZIAMENTO DELLA RICERCA SANITARIA' },
            { value: 'Q', label: 'FINANZIAMENTO A FAVORE DI ORGANISMI PRIVATI DELLE ATTIVITA’ DI TUTELA, PROMOZIONE E VALORIZZAZIONE DEI BENI CULTURALI E PAESAGGISTICI' },
            { value: 'R', label: 'SOSTEGNO DELLE ATTIVITA’ SOCIALI SVOLTE DAL COMUNE DI RESIDENZA' },
            { value: 'S', label: 'SOSTEGNO ALLE ASSOCIAZIONI SPORTIVE DILETTANTISTICHE RICONOSCIUTE AI FINI SPORTIVI DAL CONI A NORMA DI LEGGE CHE SVOLGONO UNA RILEVANTE ATTIVITA’ DI INTERESSE SOCIALE' },
        ]} />
        <XXXDest data={procedure.xxxoption} label="2x1000" ifield="xxxoption" field="xxxnote" />
    </div>)
}

export function XXXDest({ data, value, label, ifield, field, options }) {
    const [dest, setDest] = useState(data);
    const [option, setOption] = useState(value);

    return (<>
        <FormixItem name={ifield} label={"Destinazione del " + label}>
            <Radio.Group className="bg-lite padding-md radius-md bw-1 bc-lite w100" defaultValue={dest} value={dest} onChange={(e) => { setDest(e.target.value); }}>
                <Radio value={0}>SCELTA {label}</Radio>
                {/* <Radio value={1}>VEDI DOCUMENTAZIONE ALLEGATA</Radio> */}
                <Radio value={2}>NESSUNA SCELTA</Radio>
            </Radio.Group>
        </FormixItem>
        {dest === 0 &&
            <>
                {(field === 'xnote' || field === 'xxnote') && <FormixItem label="" name={field}>
                    <Radio.Group className="bg-lite padding-md radius-md bw-1 bc-lite w100" onChange={(e) => setOption(e.target.value)} options={options} />
                </FormixItem>}

                {
                    option === 'A' && <FormixItem label="Codice Stato" name="xdeclaration">
                        <Select defaultValue={0} style={{width: '180px'}}
                            options={[
                                {value: 0, label: 'Nessuno',},
                                {value: 1, label: 'Fame nel mondo',},
                                {value: 2, label: 'Calamita',},
                                {value: 3, label: 'Edilizia scolastica',},
                                {value: 4, label: 'Assistenza rifugiati',},
                                {value: 5, label: 'Beni culturali',},
                            ]}
                        />
                    </FormixItem>
                }

                {field === 'xxnote' && "NOPS".indexOf(option) > -1 && <FormixItem label="Codice fiscale del beneficiario (eventuale) 5x1000" name="xdeposit">
                    <Input placeholder="CF beneficiario"></Input>
                </FormixItem>}

                {field === 'xxxnote' && <FormixItem label="Codice fiscale del beneficiario o Nome del partito beneficiario 2x1000" name="xbank">
                    <Input placeholder="CF beneficiario"></Input>
                </FormixItem>}
            </>
        }
    </>)
}