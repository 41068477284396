import { Formix, useForm, useModel } from "@essenza/react";
import { Button, message } from 'antd';
import React, { useMemo, useRef } from "react";
import { PersonPart, AddressPart, DeclarationSchema, PersonSchema, AddressSchema, DeclarationPart, UDocument, schemaIMU } from '@pam/common';
import * as yup from 'yup';
import { PaperClipOutlined, DeleteOutlined } from '@ant-design/icons';
import { DocumentUplod } from "../../component/DocumentUpload";
import { DataSource } from "@essenza/core";

function Controller(c) {
    c.skin = ImuView;
    c.command = {
        SEND: async (docs, { model, info }) => {

            let docValid = true;

            const validateDoc = doc => {
                if (doc.required && (doc.files.length === 0)) {
                    doc.invalid = true;
                    docValid = false;
                }
            }

            docs.forEach(doc => validateDoc(doc));

            let result = await c.validate(ImuView, "form");

            console.log("LOC FORM VALIDATION", result, docValid);

            if (result.isValid && docValid) {
                const ds = model.procedure;
                if (info.istate === 2)
                    ds.node.mutate("istate", 3, ds.data);
                else if (info.istate === 5)
                    ds.node.mutate("istate", 4, ds.data);

                ds.node.mutate("filled", true, ds.data);

                ds.node.save().then(() => {
                    if (info.istate === 1) message.warning("I dati sono stati salvati con successo, la pratica sarà elaborata al ricevimento contabile del pagamento.", 16);
                    else if (info.istate === 2) message.warning("La pratica è stata inviata con successo.", 10);
                    else message.info("I dati sono stati aggiornati con successo. Segui la pratica per eventuali ulteriori richieste di informazioni.", 16);
                    c.navigate("home");
                });
            }
            else {
                message.warning("DATI MANCANTI O ERRATI, CONTROLLA IL FORM.", 8);
                if (!docValid)
                    model.refresh();
            }
        },
    }
}

export function ImuView({ source, info  }) {
    //const source = useGraph2(LessonModel, "item");
    //const [control] = useControl(Controller);
    const [model, control] = useModel(ImuView, Controller);
    const schema = useRef(yup.object(model.initSchema(yup, [DeclarationSchema, PersonSchema, AddressSchema])));
    model.procedure = source;
    const form = useForm("form", source, model, null, schema.current);

    const [attachs, docsRoot, recipt ] = useMemo(() => {
        if(!source.data) return [null, null];

        /*const schema = [
            { itype: 0, required: true, label:"Carica Documento", name: "CARTA IDENTITA'", tooltip: "Carica la carta d'identità, scansione o foto del documento fronte e retro anche in più file.", files: [] },
            { itype: 1, optional: true, label:"Carica Documento", name: "PERMESSO DI SOGGIORNO", title:"CITTADINO NON UE",  tooltip: "Carica il permesso di soggiorno, scansione o foto del documento fronte e retro anche in più file.", files: [] },
            { itype: 2, required: true, label:"Carica Documento", name: "TESSERA SANITARIA", tooltip: "Foto della tessera sanitaria, fronte e retro.", files: [] },
            { itype: 3, required: true, label:"Carica Documento", name: "VISURA CATASTALE", tooltip: "Carica la visura catastale per soggetto.", files: [] },
            { itype: 4, optional: true, label:"Carica Documento", name: "CONTRATTO DI LOCAZIONE", tooltip: "Copia contratto di locazione a “Canone Concordato” per eventuale riduzione IMU (solo qualora si intenda richiedere una riduzione dell'importo IMU).", files: [] },
        ];*/

        const r = UDocument.format(schemaIMU, source.data?.documents);
        return [schemaIMU, control.source("document", "list"), r];
    }, [source, control]);

    if (!source.data) return null;

    return (
        <>
            {recipt && <DocumentUplod source={new DataSource(recipt, docsRoot)} idparent={source.data.id} />}
            <Formix control={control} form={form} layout='vertical' className="ec-form bg-block mt-lg radius-md padding-xl shadow-md">
                <PersonPart />
                <AddressPart />
                <DeclarationPart />
                {/* <Col span={24} className="text-center">
                    <Button className="btn-dark " onClick={() => model.emit("SAVE", npath)}> {label || "Inserisci"} </Button>
                </Col> */}
            </Formix>
            <h3 className="text-center mt-lg">Caricamento dei documenti allegati richiesti</h3>
            <h6 className="text-center mt-sm">Formati conseiti pdf, jpg, jpeg, png</h6>
            {attachs.map((item, i) => <DocumentUplod key={i} source={new DataSource(item, docsRoot)} idparent={source.data.id} />)}
            <Button disabled={!info.sendable} onClick={() => model.emit("SEND", attachs, { info: info })} size="large" className="btn-pri centered my-lg" >Invia i dati per procedere con la pratica</Button>
            {info.istate === 1 && <Button onClick={() => model.emit("SEND", attachs, { info: info })} size="large" className="btn-pri centered my-lg" >Salva le Informazioni</Button>}
        </>
    )
}