import { Col, Button, Input } from 'antd';
import React, { useMemo } from "react";
import * as yup from 'yup';

import { Formix, FormixItem, useForm, useModel } from "@essenza/react";
import { PersonLitePart, AddressPart, PersonSchema, AddressSchema, CompanyLitePart, UDocument } from '@pam/common';
import { DocumentUplod } from '../../component/DocumentUpload';
import { DataSource } from '@essenza/core';

function Controller(c) {
    c.skin = ProcedurePerson;
    c.schema = {};
    PersonSchema(yup, c.schema);
    AddressSchema(yup, c.schema)
    c.command = {}
}

export function ProcedurePerson({ group, source, cf, idparent }) {
    //const [control] = useControl(Controller);
    const [model, control, state] = useModel(ProcedurePerson, Controller);
    const form = useForm("form", source, model, null, yup.object(control.schema));


    [state.attachs, state.root] = useMemo(() => {
        const schema = [
            { itype: 0, name: "CARTA IDENTITA'", tooltip: "Carica la carta d'identità, scansione o foto del documento fronte e retro anche in più file.", files: [], required: true, },
            { itype: 1, optional: true, label: "Carica Documento", name: "PERMESSO DI SOGGIORNO", title: "CITTADINO NON UE", tooltip: "Carica il permesso di soggiorno, scansione o foto del documento fronte e retro anche in più file.", files: [] },
            { itype: 2, name: "TESSERA SANITARIA", tooltip: "Foto della tessera sanitaria, fronte e retro.", files: [], required: true, },
        ];

        UDocument.format(schema, source.data?.documents, group);
        return [schema, source.discendant("documents")];
        //return new DataSource(schema, source.discendant("documents"));
    }, [source.data, group]);

    return (<Formix control={control} form={form} layout='vertical' className="ec-form bg-block mt-lg radius-md padding-xl shadow-md">
        <PersonLitePart />
        {cf && <FormixItem name="cf" label="Codice Fiscale">
            <Input />
        </FormixItem>}
        <AddressPart />
        {/* <DocumentUplod  source={attachs.getAt(0)} idparent={idprocedure} />
        <DocumentUplod  source={attachs.getAt(2)} idparent={idprocedure} /> new DataSource(item, state.root, source.data)*/}
        {state.attachs.map((item, i) => <DocumentUplod key={i} document={item} source={source.get("documents")} idparent={idparent} idlinked={source.data.id} />)}
    </Formix>);
}

export function ProcedureCompany({ itype, source, cf }) {
    const [model, control] = useModel(ProcedureCompany, Controller);

    const form = useForm("form", source, model, null, yup.object(control.schema));

    return (<Formix control={control} form={form} layout='vertical' >
        <CompanyLitePart />
        <AddressPart />
    </Formix>);
}

export function PersonLite({ source, cf, schema }) {
    const [model, control] = useModel(PersonLite, Controller);

    const form = useForm("form", source, model, null, yup.object(schema || control.schema));

    return (<Formix control={control} form={form} layout='vertical' >
        <PersonLitePart cf={cf} />
        <AddressPart />
    </Formix>);
}

