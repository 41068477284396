import { Col, DatePicker, Input, Radio, Row } from "antd";
import { useState } from "react";
import { FormixItem } from '@essenza/react';
import { DocumentUplod } from "../../../component/DocumentUpload";
import { DataSource } from "@essenza/core";

export function EntitledPart({value, attachs, root, idparent}) {
    const [entitled, setEntitled] = useState(value)

    attachs[1].required = entitled > 0;
    attachs[2].required = entitled > 0;

    return (<><FormixItem className="mt-lg" name="imarital" label="Ci sono altri aventi diritto alla pensione di reversibilità?">
        <Radio.Group className="bg-lite padding-md radius-md bw-1 bc-lite" defaultValue={entitled} value={entitled} onChange={(e) => { setEntitled(e.target.value); }}>
            <Radio value={0}>No</Radio>
            <Radio value={1}>Si, figlio minore</Radio>
            <Radio value={2}>Si, figlio studente universitario</Radio>
            <Radio value={3}>Si, figlio inabile</Radio>
            <Radio value={4}>Altro</Radio>
        </Radio.Group>
    </FormixItem>

        {entitled === 4 &&
            <>
                <FormixItem label="Altri aventi diritto" name="inote">
                    <Input placeholder="Inserisci qui gli altri aventi"></Input>
                </FormixItem>
            </>
        }

        {entitled > 0 &&
            <>
                <DocumentUplod source={new DataSource(attachs[1], root)} idprocedure={idparent} optional={{ field: "ibuilding", noLabel: "NON PRESENTI" }} />
                <DocumentUplod source={new DataSource(attachs[2], root)} idprocedure={idparent} optional={{ field: "irent", noLabel: "NON PRESENTI" }} />
            </>
        }

    </>)
}