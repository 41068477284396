import { Col, DatePicker, Input, Radio, Row } from "antd";
import { useState } from "react";
import {FormixItem} from '@essenza/react';

export function SubjectPart(value) {
    const [subject, setSubject] = useState(value)
    return (<>
        <FormixItem name="kind" label="Soggetto" >
            <Radio.Group className="bg-lite padding-md radius-md bw-1 bc-lite w100" defaultValue={subject} value={subject} onChange={(e) => { setSubject(e.target.value); }}>
                <Radio value={0}>Dichiarante</Radio>
                <Radio value={1}>Coniuge</Radio>
                <Radio value={2}>Deceduto</Radio>
                <Radio value={3}>Tutelato</Radio>
            </Radio.Group>
        </FormixItem>

        {subject === 2 &&
            <Row gutter={16}>
                <Col span={24} lg={8}>
                    <FormixItem name="odate" label="Data decesso">
                        <DatePicker style={{ width: '120px' }} format="DD-MM-YYYY" placeholder="Data del decesso"></DatePicker>
                    </FormixItem>
                </Col>
                <Col span={24} lg={16}>
                    <FormixItem label="Codice Fiscale Erede" name="extracf">
                        <Input placeholder="Indicare CF dell’erede"></Input>
                    </FormixItem>
                </Col>
            </Row>
        }
        {subject === 3 &&
            <>
                <FormixItem label="Codice Fiscale Tutore" name="extracf">
                    <Input placeholder="Indicare CF del tutore"></Input>
                </FormixItem>
            </>
        }
    </>)
}