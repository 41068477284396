import { Vista } from "@essenza/react";
import { useApp, useVista, useGraph } from "@essenza/react";
import { ProcedureModel } from "@pam/common";
import { Col, Form, Input, message, Row, Spin } from "antd";
import { useEffect, useRef, useState } from "react";
import { InputArray } from "../component/InputArray";
import { ProcedureList } from "../view/list";
import { TbRefresh } from "react-icons/tb";

export function HomeVistaControl(c) {
    c.skin = HomeVista;
    c.command = {
        SEARCH: (value, { model }) => {
            model.state.value = value;
            value = value.toLowerCase();
            c.setSource("procedures.list", model.store.filter((item) => (value === '' || (item.tsurname && (item.tsurname + ' ' + item.tname).toLowerCase().indexOf(value) > -1))));
        }
    }
}

export function HomeVista(info) {
    const [ctx, model, control] = useVista(HomeVista, HomeVistaControl);
    const app = useApp();
    const [found, setFound] = useState(!app.settings.wc || app.settings.wc.found);
    const affiliate = app.session.complete;
    const procedures = useGraph(ProcedureModel, "list");
    const filter = useRef({ state: '' }).current;
    const count = useRef(0);
    useEffect(() => {
        console.log("MODEL ", model);
        if (model) {
            let id = 0;
            const wc = app.settings.wc;
            if (wc) {
                id = wc.orderid;
            }
            model.read(ProcedureModel, m => m.opened(id)).then((result) => {
                setFound(true);
                model.store = result;
                model.state.value = '';
            });
        }
    }, [model]);

    /*useEffect(() => {
        const wc = app.settings.wc;
        if (procedures.data && wc && !wc.found) {
            if (procedures.data[0].id === wc.orderid) {
                app.settings.wc = null;
                setFound(true);
            }
            else {
                count.current++;
                if (count.current >= 4) {
                    message.warning("Pratica non ancora pronta, se il problema persiste contattare il servizio di assistenza.");
                    count.current = 0;
                }
                setTimeout(() => {
                    model.read(ProcedureModel, m => m.opened());
                }, 30000)
            }
        }
    }, [procedures.data]);*/

    console.log("HOME-VISTA");

    return (
        <Vista context={ctx} >
            <div className="w100">
                <div className="centered max-width-md">
                    {!found && <>
                        <h3 className="mt-lg text-center">Stiamo aggiornando il tuo account. Questa operazione potrebbe richiedere più di un minuto.</h3>
                        <Spin size="large" className='centered my-lg' />
                    </>}
                    {affiliate && <Row className="mt-md mx-lg" align="middle">
                        <Col flex="none">
                            <Input defaultValue="" value={model.state.value} className="mx-sm" style={{ width: '280px' }} placeholder="Cerca nominativo" onChange={(e) => model.emit("SEARCH", e.target.value)}></Input>
                        </Col>
                        <Col flex="auto">
                        <TbRefresh className="mx-sm pointer" style={{ fontSize: '24px', verticalAlign: 'middle' }} onClick={() => model.emit("SEARCH", '')} />
                    </Col>
                    </Row>}
                    <ProcedureList source={procedures} found={found} affiliate={affiliate} />
                </div>
            </div>
        </Vista>
    )
}