import { Col, DatePicker, Input, Radio, Row } from "antd";
import { useState } from "react";
import {FormixItem} from '@essenza/react';

export function MaritalPart(value) {
    const [marital, setMarital] = useState(value)
    return (<>
        <FormixItem name="imarital" label="Stato Civile">
            <Radio.Group className="bg-lite padding-md radius-md bw-1 bc-lite w100" defaultValue={marital} value={marital} onChange={(e) => { setMarital(e.target.value); }}>
                <Radio value={0}>Celibe/Nubile</Radio>
                <Radio value={1}>Congiunta/o</Radio>
                <Radio value={2}>Vedovo/a</Radio>
                <Radio value={3}>Separato/a</Radio>
                <Radio value={4}>Divorziato/a</Radio>
                <Radio value={5}>Tutelato/a</Radio>
            </Radio.Group>
        </FormixItem>

        {marital === 1 &&
            <>
                <FormixItem label="Codice Fiscale Coniuge" name="jointcf">
                    <Input placeholder="Indicare CF del coniuge"></Input>
                </FormixItem>
            </>
        }
    </>)
}