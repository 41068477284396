import { Badge, Button, Col, Input, message, Row, Segmented, Select, Tag, Upload } from "antd";
import { VistaContext, useModel, Uploader } from "@essenza/react";
import { UProcedure, Platform } from "@pam/common";
import * as yup from 'yup';
import { useContext, useMemo, useRef, useState } from "react";
import { PaperClipOutlined, DeleteOutlined, MailOutlined, AppstoreOutlined } from '@ant-design/icons';
import { ImuView } from "./procedure/imu";
import { View730 } from "./procedure/730/form";
import { LeasView } from "./procedure/locazione/form";
import { TicketView } from "./ticket/ticket";
import { RevView } from "./procedure/reversibilita/form";
import { BsExclamationDiamond } from "react-icons/bs";
import Pdf from "../assets/icon/pdf.svg";
import { View730C } from "./procedure/730/jointForm";
import { BsArrowLeft } from "react-icons/bs";
const { Option } = Select;

function Controller(c) {
    c.skin = DetailView;
    c.command = {
        SAVE: async (path, { control, data }) => {
            //console.log("SAVE", source);
            let form = control.form("user-form");
            let result = await form.validate();
            console.log("COURSE FORM VALIDATION", form, result);
            if (result.isValid) {
                result.node.save().then((r) => {
                    if (path)
                        control.navigate(path, data);
                    else
                        control.closePopup();
                });
            }
        },

        REMOVE_RECIPT: (file, { node, data }) => {
            node.mutate("recipt", false, data);
            //let mutable = node.mutable(data); => restituisce proxy.
            node.save();
        }
    }
}

export function DetailView({ source, tab }) {
    const [model, control] = useModel(DetailView, Controller);
    const [value, setValue] = useState(tab || 'D');
    const context = useContext(VistaContext);

    const info = useMemo(() => {
        const f = UProcedure.getFormat(source.data, Platform.USER);
        if (context) context.disabled = f.disabled;
        return f
    }, [source.data?.istate, source.data, source.data?.recipt]);

    if (!source.data) return null;

    const data = source.data;

    let content = null;
    const itype = source.data.itype;
    if (itype === 0)
        content = <ImuView source={source} info={info} />;
    else if (itype === 1)
        content = <View730 source={source} info={info} />
    else if (itype === 2)
        content = <LeasView source={source} info={info} />
    else if (itype === 3)
        content = <RevView source={source} info={info} />;
    else if (itype === 4)
        content = <View730C source={source} info={info} />

    const onsuccess = (r, file) => {
        data.recipt = true;
        data.urecipt = r.data
        model.refresh();
    }

    const onremove = (file) => {
        console.log("DOCUMENT REMOVED", file.name, file.id);
        control.execute("REMOVE_RECIPT", file, source);
        //TODO: source.remove(file);
    }
    return (
        <>
            {/* <Col flex="auto"><Button className="btn-dark ml-sm" size="small">Carica ricevuta pagamento</Button></Col> 
            del {new Date(data.date).toLocaleDateString("it-IT")}
            */}
            <Segmented onChange={(v) => setValue(v)} value={value} block={true} className="" options={[{ label: ' Pratica', icon: <AppstoreOutlined />, value: 'D' }, { label: ' Assistenza', icon: <MailOutlined />, value: 'T', }]} />
            {value === 'D'
                ? <>
                    <div className="ec-form mt-lg bg-block radius-md padding-md shadow-md">
                        <Row>
                            <Col className="pt-md" flex="none">
                                <h6>Ordine N.{data.id}</h6>
                            </Col>
                            <Col className="pt-md text-center" flex="auto">
                                <Button onClick={() => control.navigate("/home")} type="ghost" >
                                    <BsArrowLeft className="fs-4" />
                                </Button>
                            </Col>
                            <Col className="pt-md" flex="none">
                                <Tag style={{ color: info.typeColor }} color={info.typeRGB}>{info.type}</Tag>
                                <Tag style={{ color: info.stateColor }} color={info.stateRGB}>{info.state}</Tag>
                            </Col>
                        </Row>
                    </div>
                    {
                        data.istate === 1 &&
                        <div className="bg-block mt-lg radius-md padding-sm shadow-md text-center">
                            <Col className="pt-md pr-sm" flex="none">
                                <Uploader url="api/costumer/rupload" data={{ idcostumer: control.app.session.id, id: data.id }} onRemove={onremove} onSuccess={onsuccess}
                                    fileList={data.recipt ? [{
                                        url: "/recipt/" + control.app.session.id + "_" + data.id + data.urecipt, name: 'ricevuta' + data.urecipt,
                                        thumbUrl: data.urecipt === ".pdf" ? Pdf : null
                                    }] : null} restrictTo="png,jpg,pdf">
                                    <Button className="btn-pri">Carica Ricevuta Pagamento</Button>
                                </Uploader>
                            </Col>
                        </div>
                    }
                    {info.disabled && <div className="bg-pri mt-lg radius-md padding-sm shadow-md"><h5 className="my-lg text-center"><BsExclamationDiamond className="mr-sm" style={{ fontSize: '18px' }} />Non è possibile modificare i dati quando la pratica è {info.state}.
                        Per qualisasi modifica richiedi <Button className="fs-3" onClick={() => setValue('T')} type="link">Assistenza</Button></h5></div>}
                    {info.istate === 1 && <div className="bg-pri mt-lg radius-md padding-sm shadow-md"><h5 className="my-lg text-center"><BsExclamationDiamond className="mr-sm" style={{ fontSize: '18px' }} />Puoi compilare la pratica, la lavorazione inizierà a pagamento confermato.</h5></div>}
                    {info.istate === 5 && <><div className="bg-pri mt-lg radius-md padding-sm shadow-md"><h5 className="my-lg text-center"><BsExclamationDiamond className="mr-sm" style={{ fontSize: '18px' }} />Sono richieste informazioni aggiuntive.</h5></div><h5 className="padding-lg text-center">{data.warning}</h5></>}
                    {content}
                </>
                : <TicketView source={source.get("tickets")} procedure={source.data} />}
        </>
    )
}