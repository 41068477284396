
import { Col, Button, Row } from "antd";
import { useRef, useState } from "react";
import { useControl } from '@essenza/react';
import { MessageForm } from "./message";

function Controller(c) {
    c.skin = TicketRequest;
    c.command = {}

    /*c.observe(MessageForm, {
        SEND: async (_, { source, node }) => {
            const root = node.parent;
            if (root) {
                //const d = new Date();
                //root.mutate("date", d, source.parent);
                //source.mutate("date", d);
                return root.save().then((r) => {
                    debugger
                    //source.merge();
                    //c.navigate(-1);//"detail",{state:{id: source.data.idprocedure, tab: 'T'}});
                    //OSS: se è un nuovo message o ticket allora dovrei anche settare gli id....
                });
            }
        },

        CANCEL: () => {
            
            //c.navigate(-1);
        }
    });*/
}

export function TicketRequest({ source }) {
    const [control] = useControl(Controller);
    return (<>
        <div className="centered max-width-md padding-sm">
            <h3 className="my-lg text-center">Nuova Richiesta Supporto</h3>
            <MessageForm source={source.getLast("messages")} />
        </div>
    </>)
}