import { Formix, useForm, useModel } from "@essenza/react";
import { Button, message } from 'antd';
import React, { useMemo, useRef } from "react";
import { PersonPart, AddressPart, RevPart, DeclarationPart, RevSchema, DeclarationSchema, PersonSchema, AddressSchema, UDocument, attachREV, schemaREV } from '@pam/common';
import * as yup from 'yup';
import { PaperClipOutlined, DeleteOutlined } from '@ant-design/icons';
import { DocumentUplod } from "../../../component/DocumentUpload";
import { DataSource } from "@essenza/core";
import { EntitledPart } from "./entitled";

function Controller(c) {
    c.skin = RevView;
    c.command = {
        SEND: async (docs, { model, info }) => {

            let docValid = true;

            const validateDoc = doc => {
                if (doc.required && (doc.files.length === 0)) {
                    doc.invalid = true;
                    docValid = false;
                }
            }

            docs.forEach(doc => validateDoc(doc));

            let result = await c.validate(RevView, "form");

            console.log("LOC FORM VALIDATION", result, docValid);

            if (result.isValid && docValid) {
                const ds = model.procedure;
                if (info.istate === 2)
                    ds.node.mutate("istate", 3, ds.data);
                else if (info.istate === 5)
                    ds.node.mutate("istate", 4, ds.data);
                
                ds.node.mutate("filled", true, ds.data);

                ds.node.save().then(() => {
                    if (info.istate === 1) message.warning("I dati sono stati salvati con successo, la pratica sarà elaborata al ricevimento contabile del pagamento.", 16);
                    else if (info.istate === 2) message.warning("La pratica è stata inviata con successo.", 10);
                    else message.info("I dati sono stati aggiornati con successo. Segui la pratica per eventuali ulteriori richieste di informazioni.", 16);
                    c.navigate("home");
                });
            }
            else {
                message.warning("DATI MANCANTI O ERRATI, CONTROLLA IL FORM.", 8);
                if (!docValid)
                    model.refresh();
            }
        },
    }
}

export function RevView({ source, info }) {
    const [model, control] = useModel(RevView, Controller);
    const s = useRef(yup.object(model.initSchema(yup, [DeclarationSchema, PersonSchema, AddressSchema, RevSchema])));
    model.procedure = source;

    const form = useForm("form", source, model, null, s.current);

    const [attachs, docsRoot, schema] = useMemo(() => {
        if (!source.data) return [null, null];
        /* const doc = source.data?.documents;
         let fileList;
         if(doc && doc.length>0)
             fileList = doc[0].items;*/

        /*const attach = {
            applicant: [
                { itype: 0, required: true, label: "Carica Documento", name: "ID RICHIEDENTE", title: "CARTA IDENTITA' CONIUGE RICHIEDENTE", tooltip: "Carica la carta d'identità, scansione o foto del documento fronte e retro anche in più file.", files: [] },
                { itype: 1, optional: true, label: "Carica Documento", name: "P.SOG. RICHIEDENTE", title: "PERMESSO DI SOGGIORNO CONIUGE RICHIEDENTE", tooltip: "Carica il permesso di soggiorno, scansione o foto del documento fronte e retro anche in più file.", files: [] },
                { itype: 2, required: true, label: "Carica Documento", name: "CF RICHIEDENTE", title: "TESSERA SANITARIA", tooltip: "Foto della tessera sanitaria, fronte e retro.", files: [] },
                { itype: 3, optional: { field: "worker" }, label: "Carica Documento", name: "CU RICHIEDENTE", title: "Il richiedente è un lavoratore dipendente?", tooltip: "Carica la copia dell'ultima CU ", files: [] },
                { itype: 4, optional: { field: "retired" }, label: "Carica Documento", name: "CU RICHIEDENTE", title: "Il richiedente è un pensionato?", tooltip: "Carica la copia dell'ultima CU ", files: [] },
                { itype: 5, optional: { field: "declaration" }, label: "Carica Documenti", name: "DICHIARAZIONI RICHIEDENTE", title: "Il richiedente ha presentato dichiarazione dei redditi, 730 o modello unico, per l’anno in corso?", tooltip: "Carica la copia dell’ultima dichiarazione dei redditi, 730 o modello unico, del richiedente", files: [] },
            ],
            individual: [
                { itype: 6, required: true, label: "Carica Documento", name: "DOC. IDENTITA DEFUNTO", title: "CARTA IDENTITA SOGGETTO DEFUNTO", tooltip: "Carica la carta d'identità e il permesso di soggiorno nel caso di cittadino non dell'Unione Europea del soggetto defunto (scansione o foto del documento fronte e retro anche in più file.)", files: [] },
                { itype: 7, optional: true, label: "Carica Documento", name: "P. SOGG. DEFUNTO", title: "CITTADINO NON UE", tootltip: "Carica il Permesso di soggiorno del soggetto defunto, scansione o foto del documento fronte e retro anche in più file.", files: [] },
                { itype: 8, required: true, label: "Carica Documento", name: "CF DEFUNTO", title: "TESSERA SANITARIA SOGGETTO DEFUNTO", tooltip: "Codice fiscale del soggetto defunto (allega la foto della tessera sanitaria, fronte e retro)", files: [] },
                { itype: 9, required: true, label: "Carica Documento", name: "CERTIFICATO MORTE", tooltip: "Carica il certificato di morte del soggetto defunto.", files: [] },
                { itype: 10, optional: { field: "xdeclaration" }, label: "Carica Documenti", name: "DICHIARAZIONI DEFUNTO", title: "Il soggetto defunto aveva presentato dichiarazione dei redditi, 730 o modello unico, per l’anno in corso?", tooltip: "Allega copia dell’ultima dichiarazione dei redditi, 730 o modello unico, del soggetto defunto", files: [] },
                { itype: 11, optional: { field: "xworker" }, label: "Carica Documento", name: "CU DEFUNTO", title: "Il soggetto defunto era un lavoratore dipendente?", tooltip: "Carica la copia dell'ultima CU del soggetto defunto", files: [] },
                { itype: 12, optional: { field: "xretired" }, label: "Carica Documento", name: "CU DEFUNTO", title: "Il soggetto defunto era un pensionato?", tooltip: "Carica la copia dell'ultima CU del soggetto defunto", files: [] },
            ],
            other: [
                { itype: 13, label: "Carica Documento", name: "ID. AVENTE DIRITTO", title: "CARTA IDENTITA AVENTE DIRITTO", tooltip: "Carta d'identità degli altri aventi diritto (scansione o foto del documento fronte e retro anche in più file)", files: [] },
                { itype: 14, label: "Carica Documento", name: "CF AVENTE DIRITTO", title: "TESSERA SANITARIA AVENTE DIRITTO", tooltip: "Tessera sanitaria degli altri aventi diritto (allega la foto della tessera sanitaria, fronte e retro.)", files: [] },

                { itype: 15, required: true, label: "Carica Documento", name: "MANDATO", title: "Mandato di assistenza e rappresentanza", tooltip: "Carica il documento firmato.", files: [] },
                { itype: 16, required: true, label: "Carica Documento", name: "CONSENSO", title: "Consenso al trattamento dei dati", tooltip: "Carica i documento firmato.", files: [] },
            ],
        }

        const schema = [...attach.applicant, ...attach.individual, ...attach.other];

        if (fileList) {
            for (let k = 0; k < fileList.length; k++) {
                const file = fileList[k];
                if (!file) continue;
                schema[file.itype].files.push(file);
                if(schema[file.itype].optional?.field)
                    source.data[schema[file.itype].optional.field] = 0;
            }
        }*/
        UDocument.format(schemaREV, source.data?.documents);
        return [attachREV, control.source("document", "list"), schemaREV];
    }, [source.data, control]);

    if (!source.data) return null;

    const data = source.data;
    return (
        <>
            <Formix control={control} form={form} layout='vertical' >
                <div className="ec-form mt-lg bg-block radius-md padding-xl shadow-md">
                    <PersonPart />
                    <AddressPart />
                    <RevPart />
                </div>

                <div className="ec-form mt-lg bg-block radius-md padding-xl shadow-md">
                    <h3 className="text-center">Caricamento dei documenti necessari del coniuge richiedente</h3>
                    {attachs.applicant.map((item) => <DocumentUplod source={new DataSource(item, docsRoot)} idprocedure={data.id} />)}
                </div>

                <div className="ec-form mt-lg bg-block radius-md padding-xl shadow-md">
                    <h3 className="text-center">Caricamento dei documenti del coniuge defunto</h3>
                    {attachs.individual.map((item, i) => <DocumentUplod key={i} source={new DataSource(item, docsRoot)} idprocedure={data.id} />)}
                </div>

                <div className="ec-form mt-lg bg-block radius-md padding-xl shadow-md">
                    <h3 className="text-center">Documenti di eventuali altri aventi diritto</h3>
                    <EntitledPart idparent={data.id} value={data.entitled} root={docsRoot} attachs={attachs.other} />
                </div>

                <div className="ec-form mt-lg bg-block radius-md padding-xl shadow-md">
                    <h3 className="text-center">Scarica e allega qui i documenti da firmare</h3>
                    <ol>
                        <li>
                            <a href="http://www.praticheamiche.it/wp-content/uploads/2022/12/Pratiche-Amiche-Mandato-di-assistenza-Pensione-di-Reversibilita.pdf"
                                target="_blank" rel="noreferrer">Mandato di assistenza e rappresentanza</a><span> <b>(clicca e scarica)</b></span>
                        </li>
                        <li>
                            <a href="http://www.praticheamiche.it/wp-content/uploads/2022/12/Pratiche-Amiche-Liberatoria-Pensione-di-Reversibilita.pdf"
                                target="_blank" rel="noreferrer">Consenso al trattamento dei dati</a><span> <b>(clicca e scarica)</b></span>
                        </li>
                    </ol>

                    <DocumentUplod source={new DataSource(attachs.other[2], docsRoot)} idprocedure={data.id} />
                    <DocumentUplod source={new DataSource(attachs.other[3], docsRoot)} idprocedure={data.id} />

                    <h3 className="my-lg">Dichiarazioni necessarie</h3>
                    <DeclarationPart />
                </div>
            </Formix>
            <Button disabled={!info.sendable} onClick={() => model.emit("SEND", attachs, { info: info })} size="large" className="btn-pri centered my-lg" >Invia i dati per procedere con la pratica</Button>
            {info.istate === 1 && <Button onClick={() => model.emit("SEND", attachs, { info: info })} size="large" className="btn-pri centered my-lg" >Salva le Informazioni</Button>}
        </>
    )
}