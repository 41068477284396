import { Button } from 'antd';
import React, { useContext, useEffect, useMemo } from "react";

import { DataSource } from '@essenza/core';
import { useModel, VistaContext } from "@essenza/react";
import { ProcedurePerson } from './person';

function Controller(c) {
    c.skin = PersonList;
    c.command = {
        ADD: (item, {source, model}) => {
            source.add(item);
            model.refresh();
        },

        REMOVE: (item, {source, model}) => {
            source.remove(item);
            model.refresh();
        }
    }
}

export function PersonList({ source, group, idparent, name, title, suffix }) {
    const [model] = useModel(PersonList, Controller);
    const context = useContext(VistaContext);
    
    /*useEffect(()=>{
        console.log("PASSA-ADD PERSON BEFORE", source.data);
        if(source.empty()){
            console.log("PASSA-ADD PERSON");
            source.add({ itype: group });
            console.log("PASSA-ADD PERSON 2");
        } 
    },[source.data])*/
    /*if (!source.data) {
       source.add({ itype: 0 }); // se source non ha data quando aggiungo item lo aggiungo anche a data di ds
    }*/
    return (<>
        {source.map((s, i) => <div key={i}><h3 className='text-center mt-lg'>{title + ' ' + (i>0?String.fromCharCode(65+i):'')}{ i>0 && <Button onClick={()=>model.emit("REMOVE", s.data, null, source)} type='link'>Elimina</Button>}</h3><ProcedurePerson  group={group} source={s} idparent={idparent} /></div>)}
        {!context.disabled && <Button className='centered mt-lg btn-dark' onClick={() => model.emit("ADD", {itype: group}, null, source)}>Aggiungi {suffix}</Button>}
    </>)
}

