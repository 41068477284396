import { Input } from "antd";
import { useControl, FormixItem } from '@essenza/react';
import { DocumentUplod } from "../../../component/DocumentUpload";
import { DataSource } from "@essenza/core";

const { TextArea } = Input;

function Controller(c) {
    c.skin = BuildingPart;
    c.command = {}
}

export function BuildingPart({ attachs, root, idprocedure }) {
    const [control] = useControl(Controller);

    const bnote = <FormixItem label="Note relative ai fabbricati (variazioni in corso d’anno)" name="buildingnote">
        <TextArea rows={4} placeholder=""></TextArea>
    </FormixItem>;

    const rnote = <FormixItem label="Note relative ai fabbricati in locazione (variazioni in corso d’anno – situazioni di sfratto)" name="rentnote">
        <TextArea rows={4} placeholder=""></TextArea>
    </FormixItem>;

    return (<div className=" bg-block mt-md radius-md padding-xl shadow-md">
        <h4>Terreni e Fabbricati</h4>
        <DocumentUplod source={new DataSource(attachs[0], root)} idparent={idprocedure} />
        <DocumentUplod source={new DataSource(attachs[1], root)} idparent={idprocedure} optionalExtra={bnote } />
        <DocumentUplod source={new DataSource(attachs[2], root)} idparent={idprocedure} optionalExtra={rnote} />
    </div>)
}