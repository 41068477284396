import { Col, List, Row, Button } from "antd";

import React from 'react';
import { useControl } from '@essenza/react';
import { UProcedure } from '@pam/common';
import { BsExclamationDiamond } from "react-icons/bs"
import { MailOutlined } from '@ant-design/icons';

function ProcedureListController(c) {
    c.skin = ProcedureList;
    c.command = {
        DETAIL: (state) => {
            c.navigate("detail", { state: state });
        },
    }
}

export function ProcedureList({ source, found, affiliate }) {
    const [control] = useControl(ProcedureListController);
    console.log("PROCEDURE-LIST", source);
    if (!Array.isArray(source.data)) return found ? <h3 className="text-center mt-lg">Nessuna pratica presente.</h3> : null;
    return (
        <List dataSource={source.getCollection()}
            renderItem={item => (
                <List.Item className="po-sm">
                    <div className="w100  radius-md shadow-md bg-block">
                        <Row className={(item.istate === 0 ? "bg-dark c-white" : "bg-pri") + " padding-md radius-top-md"} >
                            <Col flex="auto">Ordine n. <b>{item.id}</b> del <b>{new Date(item.idate).toLocaleDateString("it-IT") + ' ' + (item.tname || '') + ' ' + (item.tsurname || '')}</b></Col>
                            <Col flex="none">
                                <b>{UProcedure.getState(item, 0)}</b>
                            </Col>
                        </Row>
                        <div className="padding-md w100">
                            <h2 className="mb-md">{item.title}</h2>
                            {item.istate === 1 && <div><BsExclamationDiamond className="mr-md" />Concludi il pagamento ed allega la ricevuta per procedere.</div>}
                            {item.istate === 2 && <div><BsExclamationDiamond className="mr-md " />Compila i dati per procedere.</div>}
                            {item.istate === 5 && <div><BsExclamationDiamond className="mr-md " />Informazioni Richieste.</div>}
                            {item.tickets > 0 && <div><MailOutlined className="mr-md" />Hai nuovi messaggi da leggere.</div>}
                            <Row className="mt-sm">
                                <Col flex="auto">{item.istate > 0 && <Button onClick={() => control.execute("DETAIL", { id: item.id, tab: 'T' })} type="link">Richiedi Assistenza</Button>}</Col>
                                {/* {
                                    item.istate === 1 && !item.recepit &&
                                    <Col className="pr-sm" flex="none">
                                        <Button className="btn-dark">Carica ricevuta pagamento</Button>
                                    </Col>
                                } */}
                                <Col flex="none"><Button className="btn-lite" onClick={() => control.execute("DETAIL", { id: item.id, tab: 'D' })}>{(item.istate === 2 || item.istate === 1) ? 'Compila Pratica' : 'Visualizza Pratica'}</Button></Col>
                                {/* {
                                    affiliate || (item.itype !== 1 && item.itype !== 4)
                                    ? <Col flex="none"><Button className="btn-lite" onClick={() => control.execute("DETAIL", { id: item.id, tab: 'D' })}>{(item.istate === 2 || item.istate === 1) ? 'Compila Pratica' : 'Visualizza Pratica'}</Button></Col>
                                    : <h6>Pratica compilabile dal 05/05/2023</h6>
                                } */}
                            </Row>
                        </div>
                    </div>
                </List.Item>
            )}
        />
    )
}