import { Vista } from "@essenza/react";
import { useModel, useVista, useGraph } from "@essenza/react";
import { ProcedureModel } from "@pam/common";
import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { DetailView } from "../view/detail";
import { ProcedureList } from "../view/list";

export function DetailVistaControl(c) {
    c.skin = DetailVista;
    let store = null;
    c.command = {
        SEARCH: (list, { data }) => {
            if (!store)
                store = list;
            const txt = data.state;
            c.setSource("procedure.list", store.filter((item) => (txt === '' || (item.istate === txt))));
        }
    }
}

export function DetailVista(info) {
    const [ctx, model, control] = useVista(DetailVista, DetailVistaControl);
    //const [model] = useModel();

    const data = useLocation().state;

    const procedure = useGraph(ProcedureModel, "detail");

    const filter = useRef({ state: '' }).current;

    useEffect(() => {
        console.log("MODEL ", model);
        if (model) {
            model.read(ProcedureModel, m => m.detail(data.id));
        }
    }, [model]);

    console.log("HOME-VISTA");

    return (
        <Vista context={ctx} >
            <div className="w100">
                <div className="centered max-width-lg">
                    <DetailView source={procedure} tab={data.tab} />
                </div>
            </div>
        </Vista>
    )
}