import { Col, Button, Input, Row, Upload, message, Radio, Checkbox } from 'antd';
import React, { useMemo, useRef, useState } from "react";
import * as yup from 'yup';
import { PaperClipOutlined, DeleteOutlined } from '@ant-design/icons';
import { DocumentUplod } from "../../../component/DocumentUpload";
import { Formix, FormixItem, useForm, useModel } from "@essenza/react";
import { PersonLitePart, AddressPart, DeclarationSchema, PersonSchema, AddressSchema, DeclarationPart, UDocument, schema730 } from '@pam/common';
import { SubjectPart } from './subject';
import { MaritalPart } from './marital';
import { AgentPart } from './agent';
import { DataSource } from '@essenza/core';
import { Dependant } from './dependant';
import { BuildingPart } from './building';
import { XDest } from './x1000';
import { ProcedureCompany } from '../person';
import { DepositPart } from './deposit';
import { useEffect } from 'react';

const { TextArea } = Input;

function Controller(c) {
    c.skin = View730;
    c.command = {
        SEND: async (docs, { model, info }) => {

            let docValid = true;

            const validateDoc = doc => {
                if (doc.required && (doc.files.length === 0)) {
                    doc.invalid = true;
                    docValid = false;
                }
            }
            
            docs.forEach(doc => validateDoc(doc));

            let result = await c.validate(View730, "form");

            console.log("LOC FORM VALIDATION", result, docValid);
            let r = true;
            if (result.data.iagent === 2 && result.isValid) {
                const res = await c.validate(ProcedureCompany, "form");
                r = res.isValid;
            }

            if (result.isValid && docValid && r) {
                const ds = model.procedure;
                if (info.istate === 2)
                    ds.node.mutate("istate", 3, ds.data);
                else if (info.istate === 5)
                    ds.node.mutate("istate", 4, ds.data);

                ds.node.mutate("filled", true, ds.data);

                ds.node.save().then(() => {
                    if (info.istate === 1) message.warning("I dati sono stati salvati con successo, la pratica sarà elaborata al ricevimento contabile del pagamento.", 16);
                    else if (info.istate === 2) message.warning("La pratica è stata inviata con successo.", 10);
                    else message.info("I dati sono stati aggiornati con successo. Segui la pratica per eventuali ulteriori richieste di informazioni.", 16);
                    c.navigate("home");
                });
            }
            else {
                message.warning("DATI MANCANTI O ERRATI, CONTROLLA IL FORM.", 8);
                if (!docValid)
                    model.refresh();
            }
        },
    }
}

const yup730 = (yup, schema) => {
    schema.imode = yup.number().min(0).required("Informazione obbligatoria").nullable();
    schema.iagent = yup.number().min(0).required("Informazione obbligatoria").nullable();
    schema.idependant = yup.number().min(0).required("Informazione obbligatoria").nullable();
    schema.businessname = yup.string().required("Informazione obbligatoria").nullable();
    schema.iban = yup.string().required("Informazione obbligatoria").nullable();
    schema.bank = yup.string().required("Informazione obbligatoria").nullable();
    schema.worker = yup.number().min(0).required("Informazione obbligatoria").nullable();
    schema.retired = yup.number().min(0).required("Informazione obbligatoria").nullable();
    schema.irate = yup.number().min(0).required("Informazione obbligatoria").nullable();
    schema.jointcf = yup.string().required("Informazione obbligatoria").nullable();
    schema.iload = yup.number().min(0).max(12).required("Informazione obbligatoria").nullable();
    schema.imarital = yup.number().min(0).required("Informazione obbligatoria").nullable();
    schema.isubject = yup.number().min(0).required("Informazione obbligatoria").nullable();
    schema.kind = yup.number().min(0).required("Informazione obbligatoria").nullable();
    schema.odate = yup.date().required("Informazione obbligatoria").nullable();
    schema.extracf = yup.string().required("Informazione obbligatoria").nullable();
    schema.ioption = yup.number().min(0).required("Informazione obbligatoria").nullable();
    schema.iioption = yup.number().min(0).required("Informazione obbligatoria").nullable();
    schema.iiioption = yup.number().min(0).required("Informazione obbligatoria").nullable();
    schema.inote = yup.string().required("Informazione obbligatoria").nullable();
    schema.iinote = yup.string().required("Informazione obbligatoria").nullable();
    schema.iiinote = yup.string().required("Informazione obbligatoria").nullable();
    schema.jointname = yup.string().required("Informazione obbligatoria").nullable();
    schema.iland = yup.number().min(0).required("Informazione obbligatoria").nullable();
    schema.ibuilding = yup.number().min(0).required("Informazione obbligatoria").nullable();
    schema.irent = yup.number().min(0).required("Informazione obbligatoria").nullable();
};

export function View730({ source, info }) {
    //const source = useGraph2(LessonModel, "item");
    const [model, control] = useModel(View730, Controller);
    const schema = useRef(yup.object(model.initSchema(yup, [yup730, DeclarationSchema, PersonSchema, AddressSchema])));
    model.procedure = source;

    const form = useForm("form", source, model, null, schema.current);

    const [cu, setCU] = useState(true);

    const [attachs, docsRoot, recipt] = useMemo(() => {
        if (!source.data) return [null, null];
        const r = UDocument.format(schema730, source.data?.documents);
        return [schema730, control.source("document", "list"), r];
    }, [source, control]);
    
    useEffect(()=>{setCU(!source.data?.xretired)},[source.data?.xretired])

    if (!source.data) return null;

    const onCU = (value) => {
        //attachs[4].required = value;
        setCU(value);
    }

    const data = source.data;

    attachs[4].required = cu;

    return (
        <>
            {recipt && <DocumentUplod source={new DataSource(recipt, docsRoot)} idparent={source.data.id} />}
            <h3 className='text-center my-lg'>Dati Dichiarante</h3>
            <Formix onValuesChange={(prop) => console.log("VALUES CHANGE", prop)} control={control} form={form} layout='vertical' >

                <div className="ec-form bg-block radius-md padding-xl shadow-md">
                    <PersonLitePart cf />
                    <AddressPart />
                </div>

                <div className="ec-form bg-block mt-md radius-md padding-xl shadow-md">
                    <SubjectPart value={data.kind} />
                    <MaritalPart value={data.imarital} />
                    <Dependant value={data.irate} />
                    <AgentPart value={data.iagent} source={source.get("persons").find(p => p.itype === 1).defaultValue({ itype: 1 })} />
                    <DepositPart />
                </div>
                <BuildingPart attachs={attachs} root={docsRoot} idprocedure={data.id} />
                <XDest procedure={data} />
                <DeclarationPart />

                <h3 className="text-center mt-lg">Caricamento dei documenti allegati richiesti</h3>
                <h6 className="text-center">Formati consentiti pdf, jpg, jpeg, png</h6>
                <div className="bg-block mt-lg radius-md padding-xl shadow-md" >
                    <DocumentUplod key={3} optional source={new DataSource(attachs[3], docsRoot)} idparent={data.id} />
                    <FormixItem valuePropName="checked" label="" name="xretired">
                        <Checkbox className='mt-md' onClick={(e) => onCU(!e.target.checked)}><b>Sono un/a pensionato/a ed incarico Pratiche Amiche di scaricare la Certificazione Unica</b></Checkbox>
                    </FormixItem>
                    {cu && <DocumentUplod key={4} optional source={new DataSource(attachs[4], docsRoot)} idparent={data.id} />}
                    {attachs.map((item, i) => i > 4 && i !== 33 && i !== 35 ? <DocumentUplod key={i} optional source={new DataSource(item, docsRoot)} idparent={data.id} /> : null)}
                </div>
                <div className="ec-form mt-lg bg-block radius-md padding-xl shadow-md">
                    <h3 className="text-center">Scarica e allega qui i documenti da compilare e firmare</h3>
                    <ol>
                        <li>
                            <a href="http://www.praticheamiche.it/wp-content/uploads/2023/03/DELEGA-IN-BIANCO-PER-PRELIEVO-CU-2023X2022.pdf"
                                target="_blank" rel="noreferrer">DELEGA PER L’ACCESSO ALLA CERTIFICAZIONE UNICA - CU</a><span> <b>(clicca e scarica)</b></span>
                        </li>
                        <li className='pt-sm'>
                            <a href="http://www.praticheamiche.it/wp-content/uploads/2023/03/DELEGA-IN-BIANCO-PER-PRECOMPILATO-2023X2022.pdf"
                                target="_blank" rel="noreferrer">DELEGA PER L’ACCESSO ALLA DICHIARAZIONE DEI REDDITI</a><span> <b>(clicca e scarica)</b></span>
                        </li>
                    </ol>
                    <DocumentUplod key={33} optional source={new DataSource(attachs[33], docsRoot)} idparent={data.id} />
                </div>
                <div className="ec-form mt-lg bg-block radius-md padding-xl shadow-md">
                    <FormixItem label="Note" name="note">
                        <TextArea rows={4} placeholder=""></TextArea>
                    </FormixItem>
                    <DocumentUplod key={35} optional source={new DataSource(attachs[35], docsRoot)} idparent={data.id} />
                </div>
            </Formix>
            <Button disabled={!info.sendable} onClick={() => model.emit("SEND", attachs, { info: info })} size="large" className="btn-pri centered my-lg" >Invia i dati per procedere con la pratica</Button>
            {info.istate === 1 && <Button onClick={() => model.emit("SEND", attachs, { info: info })} size="large" className="btn-pri centered my-lg" >Salva le Informazioni</Button>}
        </>
    )
}
//--allow-unrelated-histories